import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  createFilterOptions,
  Tab,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import { Add, Clear, Dataset, DeleteOutline, FileUploadOutlined, Save } from "@mui/icons-material";
import { color } from "../../../StyleData/Config";
import SRTab from "./SRTab";
import DocSR from "./DocSR";
import { Form, Formik,Field } from "formik";
import moment from "moment";
import { masterAPI } from "../../Masters/dataConfig";
import { DatePicker, DateTimePicker, LocalizationProvider,renderTimeViewClock } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import { axiosPrivate } from "../../../axios/axios";
import DocUpload from "../../../Component/DocUpload";
import NormalTable from "../../../Component/NormalTable";

const ServiceReqModal = ({ mode, handleCloseClick, editData }) => {
  const [value, setValue] = useState("1");
  const [loading,setLoading]=useState(false)
  const [problemCode, setProblemCode] = useState([]);
  const [failureClass, setFailureClass] = useState([]);
  const [asset, setAsset] = useState([]);
  const [location, setLocation] = useState([]);
  const [workType, setWorkType] = useState([]);
  const [auto,setAuto]=useState({
    asset:mode === "Edit"?editData.assetCode+" - "+editData.assetName:"",
    location:mode === "Edit"?editData.locationCode+" - "+editData.locationName:"",
  })
  const [assetInfo,setAssetInfo]=useState({})
  const [docData,setDocData]=useState([])
  const [documentValue,setDocumentValue]=useState({
    documentName: "",
    documenttype: "",
    documentcategory: "",
    documentData: "",
  })
  const [type, setType] = useState([]);
  const [category, setCategory] = useState([]);
  const [fileName, setFileName] = useState("");
  const [fileType,setFileType]=useState("")
  const [srName,setSrName]=useState([])




  
  useEffect(() => {
    getDataList()
  }, []);

  const getDataList=async()=>{
    setLoading(true);
    getData()
    if (mode === "Edit") {
      const problemLink = masterAPI["problems"].link;
     await axiosPrivate
        .get(`${problemLink}/${editData.failureClassId}`)
        .then((res) => {
          console.log(res, "problemcode");
          setProblemCode(res.data.result);
        })
        .catch((e) => {
          setProblemCode([])
          console.log(e, "error problem sr");
        });
    }
    const link = masterAPI["failureClass"].link;
   await axiosPrivate
      .get(`${link}`)
      .then((res) => {
        setLoading(false);
        setFailureClass(res.data.result);
      })
      .catch((e) => {
        setFailureClass([])
        console.log(e, "createfailureCodegetAll");
      });

   
      const typeLink = masterAPI["selectList"].documentType;
     await axiosPrivate
        .get(`${typeLink}`)
        .then((res) => {
          console.log(res);
          setType(res.data);
        })
        .catch((e) => {
          setType([])
          console.log(e, "error document type");
        });
      const categoryLink = masterAPI["selectList"].documentcategories;
     await axiosPrivate
        .get(`${categoryLink}`)
        .then((res) => {
          console.log(res);
          setCategory(res.data);
        })
        .catch((e) => {
          setCategory([])
          console.log(e, "error document category");
        });

    const locLink = masterAPI["location"].link;
   await axiosPrivate
      .get(`${locLink}`)
      .then((res) => {
        setLoading(false);
        setLocation(res.data.result);
      })
      .catch((e) => {
        setLocation([])
        console.log(e, "create location getAll");
      });

    const workTypeLink = masterAPI["selectList"].workordertype;
   await axiosPrivate
      .get(`${workTypeLink}`)
      .then((res) => {
        setLoading(false);
        setWorkType(res.data);
      })
      .catch((e) => {
        setWorkType([])
        console.log(e, "create location getAll");
      });

      const srnamelink=masterAPI["srName"].link
     await axiosPrivate.get(`${srnamelink}`).then(res=>{
      console.log(res.data.result)
        setSrName(res.data.result)
      }).catch(e=>{
        console.log(e)
        setSrName([])
      })
  }

  const getData=async()=>{
    const assetLink = masterAPI["assets"].link;
   await axiosPrivate
      .get(`${assetLink}`)
      .then((res) => {
        setLoading(false);
        setAsset(res.data.result);
      })
      .catch((e) => {
        setLoading(false)
        setAsset([])
        console.log(e, "create asset getAll");
      });
  }

  const OPTIONS_LIMIT = 10;
  const defaultFilterOptions = createFilterOptions();

  const filterOptions = (options, state) => {
    return defaultFilterOptions(options, state);
  };
  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCancel = () => {
    handleCloseClick();
  };

  const handleFailureProblem=async(fcid)=>{
    const pcLink=masterAPI["problems"].problembyFailureClass
   await axiosPrivate.get(`${pcLink}/${fcid}`).then(res=>{  
    console.log(res.data.result)
      setProblemCode(res.data.result)
    }).catch(e=>{
      setProblemCode([])
      console.log(e,"Problem Create Wo")
    })
   }

  
  const initialValue = {
    serviceRequestNameId: mode === "Edit" ? editData.serviceRequestNameId : null,
    problemId: mode === "Edit" ? editData.problemId : null,
    failureClassId: mode === "Edit" ? editData.failureClassId : null,
    locationId: mode === "Edit" ? editData.locationId : "",
    assetId: mode === "Edit" ? editData.assetId : null,
    assetName: mode ==="Edit"?editData.assetName:"",
    serviceRequestName: mode === "Edit" ? editData.serviceRequestName : "",
    description: mode === "Edit" ? editData.description : "",
    reasontoCancel: mode === "Edit" ? editData.reasontoCancel : "",
    workTypeId: mode === "Edit" ? editData.workTypeId : "d707dbff-d08f-42a8-afa1-12b2e48c266c",
    requestedDate:
      mode === "Edit"
        ? editData.requestedDate
        : moment().format("YYYY-MM-DDTHH:mm:ss"),
    stopBeginDate:
      mode === "Edit"
        ? editData.stopBeginDate
        : moment().format("YYYY-MM-DDTHH:mm:ss"),
    closingDate:
      mode === "Edit"
        ? editData.closingDate
        : moment().format("YYYY-MM-DDTHH:mm:ss"),
    approvalDate:
      mode === "Edit"
        ? editData.approvalDate
        : null,
  };

  const onSubmit = async(value) => {
    console.log(value);
    setLoading(true);
    const createLink = masterAPI["serviceRequest"].create;
    const updateLink = masterAPI["serviceRequest"].update;
    if (mode === "Create") {
      const postData={
        ...value,
        serviceRequestDocumentsList:docData
      }
     await axiosPrivate
        .post(`${createLink}`, postData)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "create serviceRequest");
        });
    } else {
      const updateAC = {
        id: editData.id,
        ...value,
      };
     await axiosPrivate
        .put(`${updateLink}`, updateAC)
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          handleCloseClick();
        })
        .catch((e) => {
          setLoading(false);
          console.log(e, "update serviceRequest");
        });
    }
  };

  const handleTab=(e,newValue)=>{
    setValue(newValue)
  }

  const handleUpload=()=>{
    console.log(documentValue)
    setDocData([...docData,{...documentValue,documentName:`${documentValue.documentName}.${fileType}`}])
    setDocumentValue({
      documentName: "",
      documenttype: "",
      documentcategory: "",
      documentData: "",
    })
    setFileName("")
    setFileType("")
  }

  const handleDelete=(del)=>{
    const filterData=docData.filter((fill,i)=>i!==Number(del.row.id))
    setDocData(filterData)
  }

  const columns = [
    // {
    //   Header: "Doc ID",
    //   accessor: "id",
    //   Cell: ({ cell }) => {
    //     return (
    //       <Typography
    //         sx={{ fontSize: "inherit", fontWeight: "inherit", color: "blue" }}
    //       >
    //         {cell.value}
    //       </Typography>
    //     );
    //   },
    // },
    {
      Header: "Doc Name",
      accessor: "documentName",
      Cell: ({ cell }) => {
        return (
          <Typography
            sx={{ fontSize: "inherit", fontWeight: "inherit"}}
            // onClick={()=>{handleDownload(cell.row.original.id)}}
          >
           {cell.value}
          </Typography>
        );
      },
    },
    {
      Header: "Doc Type",
      accessor: "documenttype",
    },
    {
      Header: "Actions",
      Cell: ({ cell }) => {
        // console.log(cell)
        return (
          <Box sx={{ whiteSpace: "nowrap" }}>
           
            <IconButton  onClick={() => {
                  handleDelete(cell);
                }}>
              <DeleteOutline
               
                sx={{
                  fontSize: color.masterEditDelButton,
                  color: color.masterEditDelButtonColor,
                }}
              />
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <Box sx={{ p: color.masterPageDialogPadding }}>
        <Formik initialValues={initialValue} onSubmit={onSubmit}>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
      <TabContext value={value}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <TabList onChange={handleTab} aria-label="lab API tabs example">
            <Tab label={mode !== "Create" ? "Update" : "Create"} value="1" />
            <Tab label="Document" value="2" disabled={mode === "Edit"} />
          </TabList>

          <IconButton onClick={handleCancel}>
            <Clear />
          </IconButton>
        </Box>
        {/* <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)"}} /> */}
        <TabPanel value="1" sx={{ p: 0, pt: 1 }}>
        <Grid container spacing={"1rem"}>
          <Grid item xs={12}>
          <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Typography
          sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
        >
          {mode==="Edit"?editData.code+"-"+editData.serviceRequestName:"New Service Request"}
       
        </Typography>
     
      </Box>
      <Divider sx={{ border: "1px solid rgb(0 0 0 / 17%)", mb: "15px" }} />
          </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Service Request Name
                </Typography>
                {/* <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                
                  name="serviceRequestName"
                  placeholder="Enter Service Request Name"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                /> */}
               
                <Field
                       name="serviceRequestName"
                      //  value={autoValue}
                      // value={values.assetName.code+"_"+values.assetName.assetName}
                           inputValue={values.serviceRequestName}
                           component={Autocomplete}
                           filterOptions={filterOptions}
                           loading={srName.length === 0}
                           options={srName} 
                           PaperComponent={CustomPaper}
                           getOptionLabel={(option) =>option.name || option}
                           freeSolo
                         onChange={(event,value) => {
                          
                       if(value){
                        setFieldValue("serviceRequestNameId",value.id)
                        setFieldValue("serviceRequestName",value.name)
                        
                       }else{
                        setFieldValue("serviceRequestNameId",null)
                        setFieldValue("serviceRequestName","")
                        
                       }
                        
                        }}
                        onInputChange={(e,v)=>{
                              setFieldValue("serviceRequestName",v)
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="serviceRequestName"
                           placeholder="Search Servicerequest Name"
                            onChange={handleChange}
                            // variant="standard"
                            size="small"
                           
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                                  {srName.length === 0 ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>),
                            }}
                           />
                           )}
                      >
                      
                        {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                      </Field>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Description
                </Typography>
                <Field
                  as={TextField}
                  size={"small"}
                  type="text"
                  name="description"
                  placeholder="Enter Description"
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Asset
                </Typography>
                {/* <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="assetId"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.assetId !== ""
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {asset.map((id, i) => (
                    <MenuItem value={id.id}>{id.assetName}</MenuItem>
                  ))}
                </Field> */}
                
                <Field
                       name="assetId"
                      //  value={autoValue}
                      // value={values.assetName.code+"_"+values.assetName.assetName}
                           inputValue={auto.asset}
                           component={Autocomplete}
                           filterOptions={filterOptions}
                           loading={asset.length === 0}
                           options={asset} 
                           PaperComponent={CustomPaper}
                           getOptionLabel={(option) =>option.code+" - "+ option.assetName || option}
                           freeSolo
                         onChange={(event,value) => {
                          
                       if(value){
                        console.log(value)
                        setFieldValue("assetId",value.id)
                        setFieldValue("locationId",value.locationId)
                        setFieldValue("failureClassId",value.failureClassesId)
                        setAuto({...auto,asset:value.code+" - "+value.assetName,location:value.locationCode+" - "+value.locationName})
                        setAssetInfo(value)
                        handleFailureProblem(value.failureClassesId)
                       }else{
                        setFieldValue("assetId",null)
                        setAuto({...auto,asset:""})
                        setAssetInfo({})
                       }
                        
                        }}
                        onInputChange={(e,v)=>{
                              setAuto({...auto,asset:v})
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="assetId"
                           placeholder="Search Asset"
                            onChange={handleChange}
                            // variant="standard"
                            size="small"
                           
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                                  {asset.length === 0 ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>),
                            }}
                           />
                           )}
                      >
                      
                        {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                      </Field>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Location
                </Typography>
                {/* <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="locationId"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.locationId !== ""
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {location.map((id, i) => (
                    <MenuItem value={id.id}>{id.locationCode+" - "+id.locationName}</MenuItem>
                  ))}
                </Field> */}
                 <Field
                       name="locationId"
                      //  value={autoValue}
                      // value={values.assetName.code+"_"+values.assetName.assetName}
                     inputValue={auto.location}
                     readOnly={assetInfo.locationId!==undefined&&assetInfo!==null}
                           component={Autocomplete}
                           filterOptions={filterOptions}
                           loading={location.length === 0}
                           options={location} 
                           PaperComponent={CustomPaper}
                           getOptionLabel={(option) => option.code+" - "+option.locationName || option}
                           freeSolo
                         onChange={async(event,value) => {
                          
                       if(value){
                        console.log(value)
                        const assetByLocation=masterAPI["assets"].assetByLocation
                       await axiosPrivate.get(`${assetByLocation}/${value.id}`).then(res=>{
                          setAsset(res.data.result)
                        }).catch(e=>{
                          setAsset([])
                          console.log(e,"error")
                        })
                        setFieldValue("locationId",value.id)
                        setAuto({...auto,asset:"",location:value.code+ " - "+value.locationName})
                        setAssetInfo({})
                    
                       }else{
                        setFieldValue("locationId",null)
                        setAuto({...auto,location:"",asset:""})
                        getData()
                        setAssetInfo({})
                        // setFieldValue("failureClassId",null)
                       }
                        
                        }}
                        onInputChange={(e,v)=>{
                          setAuto({...auto,location:v})
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="locationId"
                           placeholder="Search location"
                            onChange={handleChange}
                            size="small"
                            // variant="standard"
                           
                            InputProps={{
                              ...params.InputProps,
                              endAdornment: (
                                <React.Fragment>
                                  {/* {info.length === 0 ? <CircularProgress color="inherit" size={20} /> : null} */}
                                  {location.length === 0 ? <CircularProgress color="inherit" size={20} /> : null}
                                  {params.InputProps.endAdornment}
                                </React.Fragment>),
                            }}
                           />
                           )}
                      >
                      
                        {/* {userData.map((typeId, i) => (
                          <MenuItem value={typeId.userId} key={i}>
                            {typeId.userName}
                          </MenuItem>
                        ))} */}
                      </Field>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Failure Class
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="failureClassId"
                  readOnly={assetInfo.failureClassesId!==undefined&&assetInfo.failureClassesId!==null}
                  fullWidth
                  onChange={async(e) => {
                    console.log(e.target.value);
                    const codeLink = masterAPI["problems"].link;
                   await axiosPrivate
                      .get(`${codeLink}/${e.target.value}`)
                      .then((res) => {
                        setLoading(false);
                        setProblemCode(res.data.result);
                      })
                      .catch((e) => {
                        console.log(e, " problem service req");
                      });
                    setFieldValue("failureClassId", e.target.value);
                  }}
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.failureClassId !== null
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {failureClass.map((id, i) => (
                    <MenuItem value={id.id}>{id.code+" - "+id.failureClassName}</MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Problem Code
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="problemId"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.problemId !== null
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {problemCode.map((id, i) => (
                    <MenuItem value={id.id}>
                      {id.code + " - " + id.problemName}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Work Type
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="workTypeId"
                  fullWidth
                  displayEmpty
                  MenuProps={{ PaperProps: { sx: { maxHeight: "40vh" } } }}
                  renderValue={
                    values.workTypeId !== ""
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {workType.map((type, i) => (
                    <MenuItem key={i} value={type.value} sx={{display:type.text.toUpperCase()==="PM"?"none":"default"}}>
                      {type.text}
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ ".css-1xhypcz-MuiStack-root": { paddingTop: 0 } }}
              >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    // mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Requested Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DatePicker
                      format="DD/MM/YYYY HH:mm"
                      value={dayjs(values.requestedDate)}
                      //   sx={{".css-1xhypcz-MuiStack-root":{
                      //     padding:0
                      //   }}}
                      readOnly
                      slotProps={{
                        textField: { size: "small", margin: "-8px 0 0 0",fullWidth:true },
                      }}
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DD"
                            );
                            const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                            setFieldValue("requestedDate", dateFormat);
                          }
                        }
                      }}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ ".css-1xhypcz-MuiStack-root": { paddingTop: 0 } }}
              >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    // mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Stop Begin Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                    <DateTimePicker
                      format="DD/MM/YYYY HH:mm"
                      value={dayjs(values.stopBeginDate)}
                      ampm={false}
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      //   sx={{".css-1xhypcz-MuiStack-root":{
                      //     padding:0
                      //   }}}
                      slotProps={{
                        textField: { size: "small", margin: "-8px 0 0 0",fullWidth:true },
                      }}
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DDTHH:mm"
                            );
                            // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                            setFieldValue("stopBeginDate", dateFrom);
                          }
                        }
                      }}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid>
              {/* <Grid
                item
                xs={12}
                md={6}
                sx={{ ".css-1xhypcz-MuiStack-root": { paddingTop: 0 } }}
              >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    // mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Closing Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={dayjs(values.closingDate)}
                      //   sx={{".css-1xhypcz-MuiStack-root":{
                      //     padding:0
                      //   }}}
                      slotProps={{
                        textField: { size: "small", margin: "-8px 0 0 0",fullWidth:true },
                      }}
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DD"
                            );
                            const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                            setFieldValue("closingDate", dateFormat);
                          }
                        }
                      }}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ ".css-1xhypcz-MuiStack-root": { paddingTop: 0 } }}
              >
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    // mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Approval Date
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                    <DatePicker
                      format="DD/MM/YYYY"
                      value={values.approvalDate===null?values.approvalDate:dayjs(values.approvalDate)}
                      //   sx={{".css-1xhypcz-MuiStack-root":{
                      //     padding:0
                      //   }}}
                      slotProps={{
                        textField: { size: "small", margin: "-8px 0 0 0",fullWidth:true },
                      }}
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DD"
                            );
                            const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                            setFieldValue("approvalDate", dateFormat);
                          }
                        }
                      }}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid> */}
           
            </Grid>
        </TabPanel>
        <TabPanel value="2" sx={{ p: 0, pt: 1 }}>
          <Box sx={{p:1,display:"flex",flexDirection:"column",gap:"1rem"}}>
        <Paper elevation={3} sx={{p:1}}>
        <Grid container spacing={"1.5rem"}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      color: color.masterDialogTitleColor,
                      fontSize: color.masterDialogFontSize,
                    }}
                  >
                    Document Upload
                  </Typography>
                  <Box>
                    <IconButton disabled={fileName===""} onClick={handleUpload}>
                      <Add sx={{ color:fileName===""?"default": "#2362B4" }} />
                    </IconButton>
                   
                  </Box>
                </Box>

                <Divider
                  sx={{
                    border: "1px solid rgb(0 0 0 / 17%)",
                    //   mb: "15px",
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Document Name
                </Typography>
              < TextField
                  size={"small"}
                  type="text"
                  name="documentName"
                  value={documentValue.documentName}
                  placeholder="Enter Doc Name"
                  onChange={(e)=>{setDocumentValue({...documentValue,[e.target.name]:e.target.value})}}
                  fullWidth
                  sx={{
                    ".MuiInputBase-input": {
                      // letterSpacing: "0.2rem",

                      "&::placeholder": {
                        // color: "green",
                        opacity: 1,
                        color: color.placeholderColor,
                        fontSize: "13px",
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Document Type
                </Typography>
                <Select
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="documenttype"
                  value={documentValue.documenttype}
                  fullWidth
                  displayEmpty
                  onChange={(e)=>{setDocumentValue({...documentValue,[e.target.name]:e.target.value})}}
                  MenuProps={{
                    PaperProps: { sx: { maxHeight: "40vh" } },
                  }}
                  renderValue={
                    documentValue.documenttype !== ""
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {type.map((id, i) => (
                    <MenuItem value={id.value}>{id.text}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Document Category
                </Typography>
                <Field
                  as={Select}
                  // label={"Select"}
                  size={"small"}
                  type="text"
                  name="documentcategory"
                  value={documentValue.documentcategory}
                  onChange={(e)=>{setDocumentValue({...documentValue,[e.target.name]:e.target.value})}}
                  fullWidth
                  displayEmpty
                  MenuProps={{
                    PaperProps: { sx: { maxHeight: "40vh" } },
                  }}
                  renderValue={
                    documentValue.documentcategory !== ""
                      ? undefined
                      : () => (
                          <Typography
                            sx={{
                              color: color.placeholderColor,
                              fontSize: "13px",
                            }}
                          >
                            Select
                          </Typography>
                        )
                  }
                >
                  {category.map((id, i) => (
                    <MenuItem value={id.value}>{id.text}</MenuItem>
                  ))}
                </Field>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Document File
                </Typography>
                <Button component="label" variant="contained">
                  <FileUploadOutlined />{" "}
                  <Typography sx={{ fontSize: "0.9rem" }}>Add File</Typography>
                  <input
                    type="file"
                    // accept={"image/png, image/gif, image/jpeg"}
                    hidden
                    onChange={(e) => {
                      console.log(e.target.files[0]);
                      if (e.target.files[0] !== undefined) {
                        const file = e.target.files[0];
                        console.log(file.name);
                        const fileType = file.type.split("/")[1];
                        console.log(fileType);
                        // if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {

                        //   toast.error("Invalid format");
                        // } else {
                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = function () {
                          // console.log(reader.result);
                          const dataSplit = reader.result.split("base64,")[1];
                          // console.log(dataSplit)
                          setFileName(file.name);
                          setFileType(e.target.files[0].type.split("/")[1])
                  
                          setDocumentValue({...documentValue,documentData:dataSplit})
                        };
                        reader.onerror = function (error) {};
                        // }
                      } else {
                        setFileName("");
                        setDocumentValue({...documentValue,documentData:""})
                      }
                    }}
                  />
                </Button>
                <Typography
                  sx={{
                    // mb: color.labelmb,

                    fontSize: color.labelFont,
                    // color: color.labelColor,
                  }}
                >
                  {fileName}
                </Typography>
              </Grid>

              {/* <Grid container item xs={12} justifyContent={"flex-end"}>
                      <Button
                        onClick={() => {
                         handleClose()
                        }}
                      >
                        cancel
                      </Button>
                      <Button type="submit">submit</Button>
                    </Grid> */}
            </Grid>
        </Paper>
        <Paper elevation={3} sx={{ display: "flex", flexDirection: "column", gap: "1rem",p:1 }}>
        
        <Box>
          <Typography
           sx={{
            color: color.masterDialogTitleColor,
            fontSize: color.masterDialogFontSize,
          }}
            // sx={{
            //   color: color.sideTitleColor,
            //   fontSize: color.fontSizeSide,
            //   fontWeight: color.sideWeightFont,
            // }}
          >
            Document List
          </Typography>
          <Divider
                  sx={{
                    border: "1px solid rgb(0 0 0 / 17%)",
                      mt: "10px",
                  }}
                />
        </Box>

       
        
          <NormalTable columns={columns} data={docData} />
  
      
      </Paper>
      </Box>
        </TabPanel>
      </TabContext>
      <Grid
                container
                item
                xs={12}
                justifyContent={"flex-end"}
                gap="1rem"
              >
                <Button
                  onClick={handleCancel}
                  variant="contained"
                  sx={{
                    backgroundColor: color.TableCancelButton,
                    textTransform: "none",
                    color: color.CancelTextColor,
                    ":hover": {
                      backgroundColor: color.TableCancelButton,
                      textTransform: "none",
                      color: color.CancelTextColor,
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type={"submit"}
                  variant="contained"
                  disabled={loading}
                  sx={{
                    textTransform: "none",
                    backgroundColor: loading
                      ? "default"
                      : color.TableButtonColor,
                    "&:hover": {
                      backgroundColor: loading
                        ? "default"
                        : color.buttonDisable,
                    },
                  }}
                >
                  Submit
                </Button>
              </Grid>
      </Form>
        )}
      </Formik>
    </Box>
  );
};

export default ServiceReqModal;
