import React, { useEffect, useState } from 'react'
import Chart from "react-apexcharts";
import { color } from '../../StyleData/Config';
import { Box, Button, Dialog, Grid, Paper, Typography } from '@mui/material';
import { masterAPI } from '../Masters/dataConfig';
import { DateTimePicker, LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import moment from 'moment';
import { DemoItem } from '@mui/x-date-pickers/internals/demo';
import { axiosPrivate } from '../../axios/axios';
import LineChartValue from './LineChartValue';

const LineChart = ({assetInfo}) => {
  const [dateTime,setDateTime]=useState(moment().format("YYYY-MM-DDTHH:mm:ss"))
  const [data,setData]=useState({
    yaxis:[],
    xaxis:[]
  })
  const [status,setStatus]=useState('')
  const [create,setCreate]=useState(false)
useEffect(()=>{
   getData()
        const intervalCall = setInterval(() => {
          getData();
        }, 5000);
        return () => {
          // clean up
          clearInterval(intervalCall);
        };
    
},[])
const getData=()=>{
     const dataLink=masterAPI["LineChart"].getIotData
     console.log(moment().format("YYYY-MM-DDTHH:mm:ss"))
     axiosPrivate.post(`${dataLink}?datetime=${moment().format("YYYY-MM-DDTHH:mm:ss")}`).then(res=>{
      console.log(res)
      const xaxisReverse=res.data.result.data.xAxis.reverse()
      if(xaxisReverse[xaxisReverse.length-1]>=45&&xaxisReverse[xaxisReverse.length-1]<=55){
        console.log("check")
        setStatus("Warning")
      }else if(xaxisReverse[xaxisReverse.length-1]>55){
        setStatus("Critical")
      }else{
        setStatus("Normal")
      }
      
      setData({xaxis:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],yaxis:xaxisReverse})
     }).catch(e=>{
      console.log(e)
     })
}
const handleClose=()=>{
  setCreate(false)
}
  return (
    <>
    {/* <Box>
        <Typography
          sx={{
            color: color.masterPageTitleColor,
            fontSize: color.masterPageTitleFontSize,
            fontWeight: color.masterPageTitleFontWeight,
            mb: color.masterPageTitlemd,
          }}
        >
          {masterAPI["LineChart"].lineChartGraph}
        </Typography>
      </Box> */}
      <Paper sx={{p:"1rem"}}>
        <Grid container spacing={"1rem"}>
          <Grid container item xs={12}>
            <Grid item xs={6}>
            <Button
                variant="contained"
                sx={{ backgroundColor: "#2362B4", color: "white" }}
                onClick={()=>{
                  setCreate(true)
                }}
              >
                Add Value
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Box sx={{display:"flex",alignItems:"center",gap:"0.5rem",justifyContent:"flex-end",width:"100%"}}>
            <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    color: color.labelColor,
                  }}
                >
                  Health Status :
                </Typography>
                <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    mb: color.labelmb,
                    fontSize: color.labelFont,
                    // color: color.labelColor,
                  }}
                >
                  {status}
                </Typography>
                </Box>
            </Grid>
          </Grid>
          {/* <Grid item xs={12}>
            <Box sx={{display:"flex",gap:"1rem",alignItems:"center"}}> 
          <Typography
                    sx={{
                      fontFamily: "Nunito Sans, sans-serif",
                      fontWeight: 600,
                      color: "#656565",
                    }}
                  >
                    Date Range
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem>
                <DateTimePicker
               
                 
                
                  value={dateTime!==null?dayjs(dateTime):dateTime}
                  ampm={false}
                  format="DD/MM/YYYY HH:mm"
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  // timezone=""
                  // sx={{".css-1xhypcz-MuiStack-root":{
                  //   padding:0
                  // }}}

                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "outlined",
                      fullWidth: true,
                    },
                  }}
                  fullWidth
                  onChange={(newValue) => {
                    console.log(newValue);
                    if (newValue !== null) {
                      if (`${newValue.$d}` !== "Invalid Date") {
                        const dateFrom = moment(newValue.$d).format(
                          "YYYY-MM-DDTHH:mm:ss"
                        );
                        setDateTime(dateFrom)
                        
                        // 2022-04-17T15:30
                        // console.log(dateFrom+"T"+new Date().getHours()+":"+new Date().getMinutes()+":"+new Date().getSeconds())
                        // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                        // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                        // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                        // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                        
                       
                       
                      }
                    }
                  }}
                />
              </DemoItem>
            </LocalizationProvider>
            </Box>
          </Grid>  */}
          <Grid item xs={12}>
          <Chart
    height={350}
    width={"100%"}
    options = {{
        chart: {
          height: 350,
          type: 'line',
          id: 'Line',
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: []
            },
          },
        },
       
        annotations: {
            yaxis: [
            //   {
            //   y: 3,
            //   borderColor: '#00E396',
            //   label: {
            //     borderColor: '#00E396',
            //     style: {
            //       color: '#fff',
            //       background: '#00E396',
            //     },
            //     text: 'Support',
            //   }
            // }, 
            {
              y: 0,
              y2: 45,
              borderColor: '#000',
              fillColor: '#8FEBFE',
              opacity: 0.2,
              label: {
                borderColor: '#333',
                style: {
                  fontSize: '10px',
                  color: '#333',
                  background: '#8FEBFE',
                },
                text: 'Normal',
              }
            },
            {
              y: 45,
              y2: 55,
              borderColor: '#000',
              fillColor: '#FFD064',
              opacity: 0.2,
              label: {
                borderColor: '#333',
                style: {
                  fontSize: '10px',
                  color: '#333',
                  background: '#FFD064',
                },
                text: 'Warning',
              }
            },
            {
              y: 55,
              y2: 100,
              borderColor: '#000',
              fillColor: '#F597C0',
              opacity: 0.2,
              label: {
                borderColor: '#333',
                style: {
                  fontSize: '10px',
                  color: '#333',
                  background: '#F597C0',
                },
                text: 'Cirtical',
              }
            }
          ],
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        grid: {
          padding: {
            right: 30,
            left: 20
          }
        },
        title: {
          text: 'Degradating level log data',
          align: 'left'
        },
        labels: data.xaxis,
        xaxis: {
          title: {
            text: "Measurement Time (min)",
            style: {
              fontSize: '11px' // Set the font size of the x-axis title
            }
          },
          type: 'number',
          labels:{
            show:true
        },
        
        },
        yaxis:{
          min:0,
          max: 100,
          title: {
            text: "Degradation Level",
            style: {
              fontSize: '11px' // Set the font size of the x-axis title
            }
          },
        }
       
      }
    }
    
    
   
    type="line"
    series={[{ data: data.yaxis }]}
  />
            </Grid>
            <Grid item xs={12} sx={{ml:"3rem"}} >
              <Box sx={{width:"25%",backgroundColor:"#FFD064",p:0.5,mb:"0.5rem"}}>
              <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    // mb: color.labelmb,
                    fontSize: color.labelFont,
                    // color: color.labelColor,
                  }}
                >
                 Alarm threshold (Warning) : 45
                </Typography>
              </Box>
              <Box sx={{width:"25%",backgroundColor:"#F597C0",p:0.5}}>
              <Typography
                  sx={{
                    fontWeight: color.labelFontWeight,
                    // mb: color.labelmb,
                    fontSize: color.labelFont,
                    // color: color.labelColor,
                  }}
                >
                 Alarm threshold (Critical) : 55
                </Typography>
              </Box>
            </Grid>
        </Grid>


    
  </Paper>
  <Dialog
        open={create}
        onClose={handleClose}
        PaperProps={{
          sx: {
            maxWidth: {xs:"100vw",md:"50vw"},
            width:{xs:"100vw",md:"50vw"}
          },
        }}
        children={
          <LineChartValue
            handleCloseClick={handleClose}
      
          />
        }
      />
  </>
  )
}

export default LineChart
