import React, { useState } from "react";

import { Route, Routes } from "react-router-dom";
import Welcome from "./Pages/Welcome";

import { ThemeProvider, createTheme } from "@mui/material/styles";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


import ProtectedRoute from "./ProtectedRoute/ProtectedRoute";
import {  routerLink } from "./ProtectedRoute/rotues";
import Login from "./Pages/Sigin&SignUp/Login";
import GetInvoice from "./Single/GetInvoice";

function App() {
  const theme = createTheme({
    palette: {
      secondary: {
        main: "#FFFFFF", //nav
      },
    },
  });

  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  return (
    <ThemeProvider theme={theme}>
        
      <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/invoice" element={<GetInvoice />} />
       
        {/* <Route path="/masters/assetCategory" element={<AssetCategory />} /> */}
      
        {routerLink.map((route, i) => (
          <Route
            key={i}
            path={route.path}
            element={
              <ProtectedRoute data={route}>
                <route.component />
              </ProtectedRoute>
            }
          />
        ))}

        {/* <Route path="/masters/warranty" element={<Warranty />} />
            <Route path="/masters/assetwarranty" element={<AssetWarranty />} /> */}
      </Routes>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </ThemeProvider>
  );
}

export default App;
